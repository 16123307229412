<div class="app-page"
     [ngClass]="{ '-payhero': selected_product?.product_value === 'PAYHERO' && selected_slide !== slide_map.ALL_PRODUCTS, '-droppah': selected_product?.product_value === 'DROPPAH'}">
  <spinner class=""
           *ngIf="loading">
  </spinner>



  <!-- ########################################################################################## -->
  <!-- ######################    PAYHERO Account Create Flow   ################################## -->


  <div class="accountCreate-outerContainer -payhero"
       *ngIf="!loading && selected_product?.product_value === 'PAYHERO' && selected_slide !== slide_map.ALL_PRODUCTS"
       @fadeSlideInOut>
    <div class="accountCreate-header">
      <product-logo [productValue]="selected_product?.product_value"
                    [style.--productLogo-scale]="is_mobile ? 1.2 : 2"></product-logo>
      <div class="logoutContainer">
        <div class="accountCreate-signout-PH"
             (click)="logout()">
          Sign Out
        </div>
      </div>
    </div>
    <progress-bar [current_step]="slides.indexOf(selected_slide)"
                  [total_steps]="slides.length - 1">
    </progress-bar>
    <div>
      <button class="-type-text -color-grey-clear -upload"
              *ngIf="selected_slide.value === 'ADD_EMPLOYEES'"
              (click)="openImportModal()">
        <i class="bi-cloud-upload -disable-hover"></i> Import Employees
      </button>
    </div>

    <!-- slide 1 -->
    <div *ngIf="selected_slide === slide_map.COMPANY_INFO"
         @fadeSlideInOut>
      <div class="app-auth-pageTitleContainer">
        <h2 class="app-auth-pageTitle">
          Let's get started
        </h2>
        <h4>
          Tell us a bit about your company
        </h4>
      </div>
      <div class="app-auth-card -signup-or-login-page -payhero">

        <div class="fieldLabel">
          Company Name
        </div>
        <api-search #companyNZBNSearch
                    id="companyName"
                    [showError]="!company_name && continue_pressed"
                    [initalSearchTerm]="company_name"
                    [loading]="company_nzbn_loading"
                    [focusOnInit]="!!is_mobile ? false : true"
                    [dropdownDisabled]="!nzbn_access_token"
                    [inputPlaceholder]="'Your company name'"
                    [errorMessage]="'Company Name Required'"
                    [minimumSearchTermLength]="nzbn_minimum_search_term_length"
                    [primaryItemProperty]="'tradingNames[0]'"
                    [secondaryItemProperty]="'entityName'"
                    (searchTermUpdated)="companyNZBNSearchUpdated($event)"
                    (itemSelected)="nzbnItemSelected($event, true, true, true)"
                    (nzbnUpdated)="setNZBN($event)">
        </api-search>
        <div class="fieldLabel -extraDetail"
             *ngIf="!!company_nzbn">
          NZBN Number:&nbsp; <a href="https://www.nzbn.govt.nz/mynzbn/nzbndetails/{{company_nzbn}}"
             target="_blank">{{company_nzbn}} <i class="bi bi-box-arrow-up-right"></i></a>
        </div>

        <div class="fieldLabel">
          Industry
        </div>

        <list-drop-down class="-normalFontWeight"
                        [buttonTitle]="industry_map[selected_industry_key]"
                        [buttonTitlePlaceholder]="'Select your industry'"
                        [buttonColorClass]="'-color-white-outline'"
                        [buttonTypeClass]="'-type-dropdown'"
                        [buttonIconClass]="'ion-md-arrow-dropdown'"
                        [itemNameFontWeight]="400"
                        [listHeight]="'300px'"
                        [listFillWidth]="true"
                        [items]="industries"
                        (itemSelected)="selectIndustry($event.item)">
        </list-drop-down>

        <div class="fieldLabel">
          Region
        </div>
        <list-drop-down style="margin-bottom: 25px;"
                        [buttonTitle]="selected_region"
                        [buttonTitlePlaceholder]="'Select your region'"
                        [buttonColorClass]="'-color-white-outline'"
                        [buttonTypeClass]="'-type-dropdown'"
                        [buttonIconClass]="'ion-md-arrow-dropdown'"
                        [itemNameFontWeight]="400"
                        [listHeight]="'300px'"
                        [listFillWidth]="true"
                        [items]="regions"
                        (itemSelected)="selectRegion($event.item)">
        </list-drop-down>
        <button class="app-auth-submit -color-payhero"
                (click)="nextSlide()">
          Continue
        </button>

        <div class="ph-formItem -tryDemo"
             *ngIf="show_demo_option">
          Want to give PayHero a test drive first?&nbsp;
          <button class="-type-text app-auth-textLink -color-link-clear"
                  (click)="createDemoAccount()">Try a Demo
            Company
          </button>
        </div>
      </div>
    </div>

    <!-- slide 2 -->
    <div *ngIf="selected_slide === slide_map.PAY_CYCLE"
         @fadeSlideInOut>
      <div class="app-auth-pageTitleContainer">
        <h2 class="app-auth-pageTitle">
          Paying Employees
        </h2>
        <h4>
          What does a typical pay cycle look like for your team?
        </h4>
      </div>
      <div class="app-auth-card -signup-or-login-page -payhero">

        <div class="fieldLabel">
          How often do you pay employees?
        </div>
        <list-drop-down [buttonTitle]="selected_pay_cycle.label"
                        [buttonColorClass]="'-color-white-outline'"
                        [buttonTypeClass]="'-type-dropdown'"
                        [buttonIconClass]="'ion-md-arrow-dropdown'"
                        [listHeight]="'300px'"
                        [listFillWidth]="true"
                        [sortItems]="false"
                        [itemNameFontWeight]="400"
                        [itemNameProperty]="'label'"
                        [items]="pay_cycles"
                        (itemSelected)="selectPayCycle($event.item)">
        </list-drop-down>
        <div class="fieldLabel -extraDetail">
          You can add additional pay cycles later
        </div>

        <div class="fieldLabel"
             *ngIf="selected_pay_cycle.value === 'WEEKLY' || selected_pay_cycle.value === 'FORTNIGHTLY' || selected_pay_cycle.value === 'FOUR_WEEKLY'">
          What day do you pay your employees?
        </div>
        <list-drop-down *ngIf="selected_pay_cycle.value === 'WEEKLY' || selected_pay_cycle.value === 'FORTNIGHTLY' || selected_pay_cycle.value === 'FOUR_WEEKLY'"
                        [showError]="!selected_pay_day && continue_pressed"
                        [errorMessage]="'Pay Day Required'"
                        [buttonTitle]="selected_pay_day"
                        [buttonColorClass]="'-color-white-outline'"
                        [buttonTypeClass]="'-type-dropdown'"
                        [buttonIconClass]="'ion-md-arrow-dropdown'"
                        [buttonTitlePlaceholder]="'Select your pay day'"
                        [itemNameFontWeight]="400"
                        [sortItems]="false"
                        [listHeight]="'300px'"
                        [listFillWidth]="true"
                        [items]="week_labels"
                        (itemSelected)="selectedPayDayChanged($event.item)">
        </list-drop-down>

        <div class="fieldLabel">
          When is the start of your next pay period?
        </div>
        <date-picker name="one-time-code"
                     id="one-time-code"
                     [icon_button_class]="'-color-payhero-clear'"
                     [placeholder_title]="'Select your next pay start date'"
                     [(ngModel)]="pay_period_start"
                     [showError]="!pay_period_start && continue_pressed"
                     [errorMessage]="'Pay Start Date Required'"
                     (ngModelChange)="payPeriodChanged()"
                     (keydown)="false"
                     autocomplete="off">
        </date-picker>

        <div class="fieldLabel"
             *ngIf="selected_pay_cycle.value === 'MONTHLY'">
          When is your next pay day?
        </div>
        <date-picker *ngIf="selected_pay_cycle.value === 'MONTHLY'"
                     [showError]="!next_pay_day && continue_pressed"
                     [errorMessage]="'Pay Day Required'"
                     [placeholder_title]="'Select your next pay day'"
                     [icon_button_class]="'-color-payhero-clear'"
                     [(ngModel)]="next_pay_day"
                     (dateUpdated)="payPeriodChanged()">
        </date-picker>

        <!-- calendar view -->
        <div *ngIf="!!pay_date"
             class="calendar-detail">
          <div class="fieldLabel -extraDetail">
            <div *ngIf="selected_pay_cycle.value === 'MONTHLY'"
                 class="-type-text">
              Future pay dates will be the <b>{{!!ordinal_day_of_week ? selected_day_display : next_pay_day |
                dateToTimePipe: 'Do'}}</b> of every month.

              <button class="-type-text app-auth-textLink -color-link-clear"
                      (click)="toggleDayOfWeek()">
                Change to {{!!ordinal_day_of_week ? 'date' : 'day of week'}}.
              </button>
            </div>
          </div>

          <div class="calendar-preview-container">
            <div class="fieldLabel">
              Payroll preview
            </div>
            <div class="payPeriod-circles"> </div>
            <div class="payPeriod-circles-label">Pay Period</div>
            <div class="payDate-circle"> </div>
            <div class="payPeriod-circles-label">Pay Date</div>
          </div>
          <payhero-calendar [pay_period_start]="pay_period_start"
                            [pay_day]="pay_date"
                            [pay_period_end]="pay_period_end">
          </payhero-calendar>
          <!-- end calendar view -->
        </div>
        <button class="app-auth-submit -color-payhero"
                (click)="nextSlide()">
          Continue
        </button>
      </div>
    </div>

    <!-- slide 3 -->
    <div *ngIf="selected_slide === slide_map.ADD_EMPLOYEES"
         @fadeSlideInOut>
      <div class="app-auth-pageTitleContainer">
        <h2 class="app-auth-pageTitle">
          Time to meet the team
        </h2>
        <h4>
          Who works at {{!!company_name ? company_name : 'your company'}}?
        </h4>
      </div>
      <div class="app-auth-card -signup-or-login-page -payhero">
        <div class="employee-list-scrollOuter"
             *ngIf="ph_employees.length !== 0">
          <div class="employee"
               *ngFor="let employee of ph_employees"
               (click)="openEmployeeInfoModal(employee)">
            <div class="employee-details">
              {{employee.first_name}} {{employee.last_name}}
              <div class="email">
                {{employee.email}}
              </div>
            </div>
            <div>
              <i class="ion ion-ios-send"
                 *ngIf="employee.invite_to_portal_flag">
                <tooltip-popover [tooltipContent]="employee.first_name + ' will be invited \n to use PayHero Portal'">
                </tooltip-popover>
              </i>
            </div>
          </div>
        </div>

        <div [ngClass]="ph_employees.length > 0 ? 'add-employee' : 'add-first-employee'"
             (click)="openEmployeeInfoModal(null)">
          + Add Employee
        </div>

        <button class="app-auth-submit -color-payhero"
                (click)="nextSlide()">
          {{ getSendInvitesString() }}
        </button>
      </div>
    </div>

    <!-- slide 4 -->
    <div *ngIf="selected_slide === slide_map.COMPLETION"
         @fadeSlideInOut
         class="-lastSlide">
      <div class="app-auth-pageTitleContainer">
        <h2 class="app-auth-pageTitle">
          You're all set!
        </h2>
        <h4>
          What would you like to do next?
        </h4>
      </div>
      <div class="app-auth-card -signup-or-login-page -payhero">
        <button class="-color-white-outline"
                *ngFor="let button of action_buttons"
                (click)="payheroRedirectToProduct(button)">
          <i class="md-icon"
             [ngClass]="button.icon_class"></i>
          {{button.label}}
          <i class="ion ion-ios-arrow-round-forward"></i>
        </button>

        <button class="app-auth-submit -color-payhero"
                (click)="payheroRedirectToProduct()">
          Go to Dashboard
        </button>

        <div class="-block-ph-app-message">
          <br>
          <h4 style="font-weight: 600">Sign in on a larger device to access your PayHero account</h4>
          <br>
          <h4>In the meantime, visit our <a [href]="'https://support.payhero.co.nz/hc/en-us'"
               target="_blank">support
              center</a> to learn more about using PayHero.
          </h4>
        </div>

      </div>

    </div>

    <div class="app-auth-mobileGraphicFooter"
         *ngIf="selected_product?.product_value === 'PAYHERO' && !is_mobile">
      <img [src]="'assets/graphics/Dashboard_Graphic_Day.svg'">
    </div>
  </div>




  <!-- ########################################################################################## -->
  <!-- ######################   Droppah Account Create   ######################################## -->

  <div class="accountCreate-outerContainer -droppah"
       *ngIf="selected_product?.product_value === 'DROPPAH'">

    <div class="accountCreate-droppah-inner-left">
      <div class="app-auth-droppah-content">
        <h1>
          Knives sharp, aprons on – Let’s get into it.
        </h1>
        <h4>
          Time to set you up for some bitchin’ kitchen switchin’ in Droppah.
        </h4>
      </div>
      <div class="app-auth-card">
        <div class="ph-formItem-container -smallForm">

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              What’s the name of your company?
            </div>

            <div class="ph-formItem-value">
              <input class="app-input -inputLarge"
                     name="account-name"
                     type="text"
                     id="companyName"
                     placeholder="Company Name"
                     [(ngModel)]="company_name">
            </div>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              How can we reach you?
            </div>
            <div class="ph-formItem-value">
              <input class="form-control"
                     placeholder="Mobile Number"
                     [mask]="'00000000000'"
                     [(ngModel)]="contact_phone">
            </div>
          </div>


          <div class="ph-formItem">
            <div class="ph-formItem-label">
              How many people are in your crew?
            </div>

            <list-drop-down [buttonTitle]="number_of_employees"
                            [buttonTitlePlaceholder]="'Number of Employees'"
                            [buttonColorClass]="'-color-white-outline'"
                            [buttonTypeClass]="'-type-dropdown'"
                            [buttonIconClass]="'ion-md-arrow-dropdown'"
                            [listFillWidth]="true"
                            [sortItems]="false"
                            [items]="employee_options"
                            (itemSelected)="selectNumberOfEmployees($event.item)">
            </list-drop-down>
          </div>

          <div class="ph-formItem">
            <div class="ph-formItem-label">
              What’s your specialty?
            </div>

            <list-drop-down [buttonTitle]="selected_business_type"
                            [buttonTitlePlaceholder]="'Business Type'"
                            [buttonColorClass]="'-color-white-outline'"
                            [buttonTypeClass]="'-type-dropdown'"
                            [buttonIconClass]="'ion-md-arrow-dropdown'"
                            [itemNameFontWeight]="400"
                            [sortItems]="false"
                            [items]="business_types"
                            (itemSelected)="selectBusinessType($event.item)">
            </list-drop-down>
          </div>



          <div class="ph-formItem">
            <div class="app-auth-accessAgreement">
              <ph-checkbox [(ngModel)]="user_terms_agreed"
                           [ngModelOptions]="{ standalone: true }">
              </ph-checkbox>

              <span>I acknowledge that I have read and agree to FlexiTime's
                <a href="https://www.flexitime.works/subscription-agreement?reload"
                   target="_blank">
                  Subscription Agreement
                </a>
              </span>
            </div>
          </div>

          <div class="ph-formItem">
            <button class="-color-success app-auth-submit"
                    (click)="createNewAccount()"
                    [disabled]="loading || !validateAccountCreation(false)">
              Get me in
            </button>
          </div>
          <div class="accountCreate-droppah-footer">
            <img [src]="'assets/product-imgs/flexitime/From_FlexiTime_Horiz_White.svg'">
            <app-footer-user-agreements></app-footer-user-agreements>
          </div>
        </div>
      </div>
    </div>

    <div class="accountCreate-droppah-inner-right">
      <img [src]="'assets/graphics/Droppah/Juggle.svg'">
    </div>

    <img src="assets/product-imgs/droppah/Droppah_Full_White.svg"
         class="accountCreate-droppah-logo">

  </div>


  <!-- ########################################################################################## -->
  <!-- ######################   General Account Create   ######################################## -->

  <div class="accountCreate-outerContainer"
       *ngIf="!loading && (selected_product?.product_value !== 'PAYHERO' || selected_slide === slide_map.ALL_PRODUCTS) && selected_product?.product_value !== 'DROPPAH'">

    <div class="logoutContainer">
      <div class="accountCreate-logout"
           (click)="logout()">
        Sign Out
      </div>
    </div>


    <div class="app-auth-welcome">
      <img class="app-auth-welcomeLogo"
           [src]="product_theme.headerLogo">

      <h1>Create Account</h1>
      <h4>Set up your Account and get started with {{product_theme.displayName}}</h4>
    </div>

    <div class="app-auth-card">

      <div class="app-auth-errorMessage"
           [ngClass]="{ '-hidden': !ird_number || ird_number_valid }">
        <i class="ion ion-ios-warning sm-icon"></i> Invalid IRD Number
      </div>

      <div class="ph-formItem-container -smallForm">

        <div class="ph-formItem"
             *ngIf="global_product === 'FLEXITIME'">
          <div class="ph-formItem-label">
            Product
          </div>
          <div class="ph-formItem-value">
            <list-drop-down [itemNameProperty]="'product_name'"
                            [itemProductValueProperty]="'product_value'"
                            [buttonTitle]="selected_product?.product_name"
                            [buttonProductValue]="selected_product?.product_value"
                            [buttonTitlePlaceholder]="'Select a Product'"
                            [buttonColorClass]="'-color-white-outline'"
                            [buttonTypeClass]="'-type-dropdown'"
                            [buttonIconClass]="'ion-md-arrow-dropdown'"
                            [buttonIconAlignLeft]="false"
                            [listFillWidth]="true"
                            [sortItems]="false"
                            [items]="product_dropdown"
                            (itemSelected)="selectProduct($event.item)">
            </list-drop-down>
          </div>
        </div>

        <div class="app-infoPanel -warningPanel"
             *ngIf="is_partner && selected_product?.product_value === 'KARMLY'">
          <div class="app-infoPanel-row"
               [innerHTML]="partner_karmly_warning_message | safeHtml">
          </div>
        </div>

        <ng-container *ngIf="!(is_partner && selected_product?.product_value === 'KARMLY')">

          <div class="ph-formItem -tryDemo"
               *ngIf="show_demo_option && selected_product?.product_value !== 'PAYHERO' && selected_product?.product_value !== 'DROPPAH'">
            <a (click)="createDemoAccount()">
              Try a demo account&nbsp;
            </a>
          </div>

          <div class="ph-formItem"
               *ngIf="selected_product?.product_value !== 'PAYHERO' && selected_product?.product_value !== 'KARMLY'">
            <div class="ph-formItem-label">
              {{selected_product?.product_value === 'DROPPAH' ? 'Company Name' : 'Account Name'}}
            </div>

            <div class="ph-formItem-value">
              <input class="app-input -inputLarge"
                     name="account-name"
                     type="text"
                     id="companyName"
                     placeholder="{{selected_product?.product_value === 'DROPPAH' ? 'Company Name' : 'Account Name'}}"
                     [(ngModel)]="company_name">
            </div>
          </div>


          <div class="ph-formItem"
               *ngIf="selected_product?.product_value === 'KARMLY'">
            <div class="app-auth-accessAgreement">
              <ph-checkbox [(ngModel)]="user_terms_agreed"
                           [ngModelOptions]="{ standalone: true }">
              </ph-checkbox>

              <span>I acknowledge that I have read and agree to FlexiTime's
                <a href="https://www.flexitime.works/subscription-agreement?reload"
                   target="_blank">
                  Subscription Agreement
                </a>
              </span>
            </div>
          </div>


        </ng-container>

        <div class="ph-formItem">
          <button class="-color-success app-auth-submit"
                  *ngIf="selected_product?.product_value !== 'PAYHERO'"
                  (click)="createNewAccount()"
                  [disabled]="loading || !validateAccountCreation(false)">
            Create Account
          </button>

          <button class="-color-payhero app-auth-submit"
                  *ngIf="selected_product?.product_value === 'PAYHERO'"
                  (click)="nextSlide()">
            Next
          </button>
        </div>

      </div>
    </div>

  </div>

  <footer class="app-footer"
          *ngIf="selected_product?.product_value !== 'PAYHERO' && selected_product?.product_value !== 'DROPPAH'">
    <div class="app-footer-fromFlexitime">
      <img *ngIf="selected_product?.product_value !== 'DROPPAH'"
           [src]="product_theme.loginFooterProductLogos.FLEXITIME" />
    </div>

    <app-footer-user-agreements></app-footer-user-agreements>

  </footer>

</div>
