<div class="ph-modal-content">
  <div class="ph-modal-header">
    <span class="ion ion-ios-card">
    </span>
    <div class="headerCenter">
      Update Credit Card Details
    </div>

    <i class="ion ion-ios-close ph-modal-close lg-close"
       (click)="cancel()">
    </i>
  </div>


  <div class="ph-modal-container">
    <div class="ph-formItem-value">
      <p>
        We're changing our credit card service provider.
        Please update your credit card details before the <b>31st of December</b> to ensure uninterrupted access to your accounts.
      </p>
      <p>
        For more information, check out this
         <a href="https://support.flexitime.works/hc/en-us/articles/11194589323279-Changing-Credit-Card-Payment-Provider"
          target="_blank"
          class="icon-link">
       Support Article
     </a>
      </p>
    </div>

    <div class="ph-modal-btnFooter">
      <button class="-color-white-outline ph-modal-btnRight"
              (click)="cancel()">
        I'll do it later
      </button>
      <button class="-color-success ph-modal-btnRight"
              (click)="confirm()">
        Update now
      </button>

    </div>
  </div>
</div>
